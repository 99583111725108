<template>
  <b-modal
    id="modal-edit-workspace"
    ref="modal-edit-workspace"
    centered="centered"
    :title="(workspace.id ? 'Modifier' : 'Ajouter') + ` un environnement`"
    @ok="create"
  >
    <div
      v-if="isCreatingWorkspace || isUpdatingWorkspace || isLoadingWorkspace"
    >
      <div class="text-center flex-center">
        <div class="loading-bg-inner">
          <div class="loader">
            <div class="outer"></div>
            <div class="middle"></div>
            <div class="inner"></div>
          </div>
        </div>
        <div class="mt-5">
          <br /><br /><br />
          Chargement...
        </div>
      </div>
    </div>
    <validation-observer v-else ref="formEditWorkspace">
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group
            label="Nom *"
            label-for="socialReason"
            class="pr-1"
            style="flex: 1"
          >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="socialReason"
                v-model="workspace.socialReason"
                :state="errors.length > 0 ? false : null"
                class="not-autocomplete"
                autocomplete="nope"
                aria-autocomplete="nope"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex">
          <div class="d-flex">
            <b-form-checkbox
              v-model="workspace.mergeInstitutionsData"
              class="custom-control-primary"
            >
              Fusioner les données des sociétés
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-form-group
            label="Modules"
            label-for="module"
            class="pr-1"
            style="flex: 1"
          >
            <div class="d-flex" v-for="(item, index) in modulesList">
              <b-form-checkbox
                v-model="workspace.modules"
                :value="item.id"
                class="custom-control-primary mb-1"
                :label="item.label"
              >
                {{ item.label }}
              </b-form-checkbox>
            </div>
          </b-form-group>
        </b-col>
      </b-row> </validation-observer
    ><template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <b-button class="float-right" variant="primary" @click="ok()">{{
          workspace.id ? "Modifier" : "Ajouter"
        }}</b-button>
        <b-button
          class="float-right mr-1"
          variant="outline-primary"
          @click="cancel()"
          >Annuler</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import { BFormCheckbox } from "bootstrap-vue";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

export default {
  data() {
    return {
      required,
      url,
      email,
      workspace: {
        socialReason: "",
        modules: [],
        mergeInstitutionsData: false,
      },
      institutionTypeInstitution: [],
    };
  },
  methods: {
    async popupCreateWorkspace(id) {
      this.initializeForm();
    },
    initializeForm() {
      this.workspace = {
        socialReason: null,
        modules: [],
        mergeInstitutionsData: false,
      };
      this.institutionTypeInstitution = [];
    },
    create(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.formEditWorkspace.validate().then(async (success1) => {
        if (success1) {
          await this.createWorkspace({ workspace: this.workspace }).then(
            async (res) => {
              this.updateWorkspaceModule({
                modules: this.workspace.modules,
                workspaceId: res.id,
              });
              this.$nextTick(() => {
                this.$refs["modal-edit-workspace"].toggle("#toggle-btn");
                this.$emit("refreshGrid");
              });
            }
          );
        }
      });
    },
    ...mapActions([
      "createWorkspace",
      "updateWorkspace",
      "updateWorkspaceModule",
      "getModules",
    ]),
  },
  computed: {
    ...mapGetters([
      "isCreatingWorkspace",
      "modulesList",
      "institution",
      "isUpdatingWorkspace",
      "isLoadingWorkspace",
    ]),
  },
  created() {
    this.getModules({});
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
};
</script>
